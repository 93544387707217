<template >
  <vue-apex-charts
    ref="demoChart"
    height="350"
    :options="options.chartOptions"
    :series="options.series"
  />
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  components: {
    VueApexCharts,
  },
  props: {
    options: {
      type: Object,
      required: true,
      default: null,
    },
  },
  created() {
  }, 

  methods: {},

  mounted() {

    this.$refs.demoChart.updateOptions({
      chart: {
        events: {
          mounted: (event, chartContext, config) => {
            this.$emit('elementLoaded', event)
          },
        },
      },
    });

  },

  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
